
@import url('../animations.css');



.Home img {
    border-radius: 10px;
    margin-top: 20px;
    max-height: 330px;
    transition: transform 0.3s ease;
}

.Home img:hover {
    transform: scale(1.02); 

}

.Home .parallax-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.Home .parallax-container video {
    margin-top: 60px;
    max-height: 700px;
}

@media (max-width: 700px) {
    .Home .parallax-container video {
        margin-top: 60px;
        max-height: 700px;
        max-width: 700px;
    }
}

.Home .cards {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 3px 20px;
    align-items: flex-start;
}
.Home .cards-finca {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 3px 20px;
    align-items: center;
    justify-content: center;
}
.Home .cards-biografia {
    width: 90%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 3px 0px;
    align-items: flex-start;
    gap: 10px;
}

.container-comilla-linea {
    display: flex;
    align-items: center;
}

.linea-dorada {
    width: 90%;
    height: 1px;
    background-color: var(--colorIcons);
    margin: 0 auto;
    margin-top: 20px;
}

.linea-dorada2 {
    flex-grow: 1;
    width: 90%;
    background-color: var(--colorIcons);
    margin: 0 auto;
    margin-top: 10px;
    height: 1px;
    display: inline;
    margin-left: 5px
}

.linea-dorada3 {
    width: 90%;
    height: 1px;
    background-color: var(--colorIcons);
    margin: 0 auto;
    margin-top: 10px;
}

.texto-comillas {
    font-size: 40px;
    color: var(--colorIcons);
    font-family: var( --fuente-secundaria);
}


.Home .products-title p {
    font-family: var(--fuente-regular);
    font-size: 36px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.Home .products-title-experience p {
    font-family: var(--fuente-regular);
    font-size: 36px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 20px 80px 0 80px;
}
.Home .wines-title-experience p {
    font-family: var(--fuente-regular);
    font-size: 46px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 80px 0 80px;
}
.Home .wines-title-experience li { 
    font-size: 20px;
    min-height: auto; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: justify;
    padding: 5px;
    word-spacing: -1px;
    hyphens: auto;
    font-family: var(--fuente-terciaria);
    font-weight:bold;
    margin-top: 1px;
    margin-bottom: 2px;
    color: var(--colorIcons);
    line-height: 2;
    min-width: auto;
  }



.Home .products-title .letraGrande {
    font-family: var(--fuente-regular);
    font-size: 36px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
   /*  font-weight:200; */

    
}
.Home .wines-title-experience .letraGrandeExperience {
    font-family: var(--fuente-regular);
    font-size: 46px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
 /*    font-weight: bold; */
}
.Home .texto-container-biografia .letraGrande {
    font-family: var(--fuente-regular);
    font-size: 36px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
   /*  font-weight: bold; */
}

.Home .products-title-experience .letraGrandeExperience {
    font-family: var(--fuente-regular);
    font-size: 36px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-weight: bold;
    color: var(--colorIcons)
}

.Home .products {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 3px 20px;
    align-items: flex-start;
}

.Home .products img {
    border-radius: 10px;
    margin-top: 5px;
    max-height: 200px;
}

.Home .products .image-cards {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: flex-start;
}

.Home .products .image-cards2 {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: flex-start;
}

.Home .products .image-cards-experience {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: flex-start;
}

.Home .products .contact-image-cards {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: flex-start;
}

@media (max-width: 700px) {
    .Home .products .contact-image-cards {
        max-width: 700px;
    }
}

.Home .products .contact-image-cards-form {
    width: 50%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: flex-start;
}

.Home .products article  {
    width: 33%;
    margin-left: 5px;
    padding: 10px;
}

.Home .products .image-cards .dueño {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); /* Estado inicial */
    transition: box-shadow 0.5s ease; /*transición suave */
    &:hover {
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35); /* Estado final al hacer hover */
      }
    
}


.Home .products .texto-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 130px;
    margin-bottom: 25px;
}

.Home .products .texto-item .titulo-negro {
    font-family: var(--fuente-regular);
    font-size: 36px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.Home .products .texto-item .subtitulo-dorado {
    font-family: var(--fuente-terciaria);
    font-size: 24px;
    color: var(--colorIcons);
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 1.5;
}

.Home .products .descripcion .total-precio {
    text-align: right;
    font-family: var(--fuente-terciaria);
    font-size: 26px;
    font-weight: lighter;
    margin-top: 10px;
    margin-right: 10px;
    font-style: italic;
}


.Home .products .experience-container .image-cards-experience {
    min-height: 400px;
    display: flex;
    flex-direction: column; 
    justify-content: flex-end; 
    align-items: center;
    max-width: 100%;
    min-height: 400px;
    min-height: 400px;
}
.Home .products .experience-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    min-height: 350px; /* IMPORTANTE PROPIEDAD PARA QUE QUEDE ALINEADO */
}



.Home .products .experience-container img {
    margin-top: 20px;
    align-self: flex-end;
    padding: 0px;
    border-radius: 2%;
}


.Home .products .experience-descripcion {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.Home .products .image-cards-experience .experience-container .experience-descripcion .statements { 
    font-size: 20px;
    min-height: 200px; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
    padding: 3%;
    word-spacing: -1px;
    hyphens: auto;
    font-family: var(--fuente-terciaria);
    font-weight:300;
    margin-top: 1px;
    margin-bottom: 5px;
    color: black;
    line-height: 2;
    min-width: auto;
  }
.Home .products .image-cards-experience .experience-container .experience-descripcion .author-statement { 
    font-size: 15px;
    min-height: auto; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: justify;
    padding: 3%;
    word-spacing: -1px;
    hyphens: auto;
    font-family: var(--fuente-terciaria);
    font-weight:bold;
    margin-top: 1px;
    margin-bottom: 5px;
    color: var(--colorIcons);
    line-height: 2;
    min-width: auto;
  }


.Home .products .container-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 10px;
    max-height: 250px;
    min-height: 150px;
    min-width: 350px;
    
}



/* @media (max-width: 700px) {
    .Home .products .container-buttons {
        max-width: 200px;
    }
} */


.Home .products .container-buttons button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 25px;
    font-family: var(--fuente-regular);
    font-size: 17px;
    border: 1px solid var(--colorIcons);
    background-color: white;

    box-shadow: 0 0 0 0 var(--colorIcons); /* Estado inicial */
    transition: box-shadow 0.5s ease; /*transición suave */
    &:hover {
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.283); /* Estado final al hacer hover */
      }
}

.Home .products .container-buttons .mail-button  {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-bottom: 16px;
    width: 100%;
    border-radius: 25px;
    font-family: var(--fuente-regular);
    font-size: 17px;
    border: 1px solid var(--colorIcons);
    background-color: white;

    box-shadow: 0 0 0 0 var(--colorIcons); /* Estado inicial */
    transition: box-shadow 0.5s ease; /*transición suave */
    &:hover {
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.283); /* Estado final al hacer hover */
      }
}

.Home .products .container-buttons .social-media-button {
    background-color: var(--colorIcons);
/*     font-weight: bold; */
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

}

/* Estilos de los íconos: */

.icon {
    width: 24px;
    height: 23px;
    vertical-align: middle;
    fill: currentColor;
    margin-right: 10px;
    margin-left: 5px;
}

.Home .products .container-buttons .social-media-button {
    display: flex;
    align-items: center;
    background-color: var(--colorButtons);
    padding: 10px 20px;
    background-color: var(--colorIcons);

     &:hover {
      /* animation: flip-2-hor-top-1 1s ease;  */
      animation:tracking-in-expand 1s ease;
      background-color: var(--colorIcons);
    }
}

/* Estilos para el texto NUESTRAS REDES */
.our-social-networks-text {
    font-size: 15px;
    font-family: var(--fuente-regular);
    text-align: center;
/*     font-weight: bold; */
    justify-content: center;
    margin-right: 10px;
}

/* Estilos para el mensaje de texto */

.Home .products .descripcion .author-statement {
    text-align: left;
    font-family: var(--fuente-terciaria);
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 20px;
    font-style: italic;
}

.Home .fraseFinal {
    text-align: left;
    font-family: var(--fuente-terciaria);
    font-size: 26px;
    color: var(--colorIcons);
    font-weight: bold;
    margin-top: 5px;
    padding: 3px 65px;
    text-align: center;
    margin-top: 20px;

}

/* ESTA PARTEEE OJOO parte va a tener que repetirse para tamaño pequeño porque sino rompe todo: */
.Home .cards .image-cards {
    width: 50%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: flex-start;
}
.Home .cards .image-cards-finca {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    padding: 0 20px;
    align-items: center;
    justify-content: center;

}
.Home .cards-finca .image-cards-finca img {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover; /* Otra opción: contain */
    height: 100%;
    width: 100%;
    min-width: 950px; 
    min-height: 300px; 
    max-height: 300px;
}
.Home .cards .image-cards img {
    object-fit: cover; /* Otra opción: contain */
    height: 100%;
    width: 100%;
    min-height: 350px; /* ATENTI CON ESTO QUE ES PARA EL TAMAÑO MINIMO ALTO DE LAS IMAGENES DEL INICIO */
    max-height: 350px;
}

.Home .cards .image-cards .cadaVino  img {
    object-fit: cover; /* Otra opción: contain */
    height: 100%;
    width: 100%;
    min-height: 480px; /* ATENTI CON ESTO QUE ES PARA EL TAMAÑO MINIMO ALTO DE LAS IMAGENES DEL INICIO */
    max-height: 650px;
}
.Home .cards-biografia .image-cards-biografia {
    width: 50%;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 ;
    padding: 0 5px;
    align-items: flex-start;
    justify-content: center;   
}

.Home .cards-biografia .image-cards-biografia img {
    object-fit: cover; /* Otra opción: contain */
    height: 100%;
    width: 100%;
    min-height: 600px; /* ATENTI CON ESTO QUE ES PARA EL TAMAÑO MINIMO ALTO DE LAS IMAGENES DEL INICIO */
    max-height: 680px;
}


/* CONTENEDOR DEL BOTON DESCARGAR FICHA TÉCNICA DEL HOME: */

 .home-descargaFicha-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}
.home-descargaFicha-container .container-icono-boton { 
    width: 100%;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: justify;
    padding: 5px;
    font-family: var(--fuente-terciaria);
    font-weight:bold;
 /*    margin-top: 10px; */
/*     margin-bottom: 10px; */
    color: var(--colorIcons);
  }

  .home-descargaFicha-container .container-icono-boton .iconoDescargaFichaFinca {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: var(--colorIcons);
    font-family: var( --fuente-regular);
    cursor: pointer;
 
}
  .home-descargaFicha-container .container-icono-boton .descargarFichaFinca-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 20px;
    color:var(--colorSubTitle);
    font-family: var(--fuente-regular);
    color: var(--colorIcons);
    background-color: transparent;
    border: 0px solid rgba(0, 0, 0);
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
/*     margin-top: 15px; */
    margin-left: 15px;
}
.home-descargaFicha-container .container-icono-boton .iconoDescargaFichaFinca img {
    margin-top: 0px;
 /*    min-height: 10px;
    max-height: 30px */
}




@media (max-width: 1000px) {
    .Home .cards .image-cards img {
        min-height: 250px;
    }
}
@media (max-width: 700px) {
    .Home .cards .image-cards img {
        min-height: 200px;
    }
}

.Home .products .descripcion {
    font-family: var(--fuente-regular);
    font-size: 34px;
    color: var(--colorIcons);
    text-align: center;
    font-weight: bold;
}


.Home .products .contacto-descripcion {
    font-family: var(--fuente-regular);
    font-size: 34px;
    color: var(--colorIcons);
    text-align: center;
}

.Home .products .paises-contacto {
    font-family: var(--fuente-regular);
    font-size: 34px;
    color: var(--colorIcons);
    text-align: center;
    font-weight: lighter;
}

.Home .products .detail li {
    font-family: var(--fuente-terciaria);
    font-size: 16px;
    color: gray;
    text-align: left;
    font-weight: lighter;
    line-height: 1.5;
    padding: 0 25px;
}

.Home .products .detail li .uvitas {
    width: 22px;
    height: 20px;
    margin-top: 10px;
}



.Home .cards article {
    width: 33%;
    margin-left: 5px;
}
/* .Home .cards article-biografia {
    width: 33%;
    margin-left: 5px;
} */


.Home .texto-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}
.Home .texto-container-biografia {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-right: 5%;

}


.Home .cards .texto-item p {
    display: flex;
}

.Home .cards .texto-item .titulo-negro {
    font-family: var(--fuente-regular);
    font-size: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
.Home .cards-biografia .texto-item .titulo-dorado-biografia {
    font-family: var(--fuente-terciaria);
    font-style: italic;
    font-size: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: var(--colorIcons);

}
.Home .cards-biografia .texto-item .titulo-negro-biografia {
    font-family: var(--fuente-regular);
    font-size: 36px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: 'black';
    margin-right: 25%;

}

.Home .cards .texto-item .subtitulo-dorado {
    font-family: var(--fuente-terciaria);
    font-size: 24px;
    color: var(--colorIcons);
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 1.5;
}
.Home .cards-biografia .texto-item .subtitulo-dorado-biografia {
    font-family: var(--fuente-terciaria);
    font-size: 15px;
    color: 'black';/* var(--colorIcons); */
    display: flex;
    justify-content: center;
    text-align: justify;
    line-height: 2;
    margin-top: 12px;
}

.Home .cards article p {
    letter-spacing: 1px;
    color: #161922;
    margin: 10px 0;
}

.Home .cards-biografia article p {
    letter-spacing: 1px;
    color: #161922;
    margin: 10px 0;
}


.Home .cards article p span {
    font-style: italic;
    font-weight: bold;
}

.Home .cards article small {
    letter-spacing: 1px;
    color: #626262;
}

/* Media query celular: */

/* Estilos para pantallas móviles con un ancho máximo de 375px */

/* primera parte de texto y 3 imágenes:  */
/* Estilos para pantallas pequeñas (máximo 375px de ancho) */

/* ------------------------------------------------------------------------------------------------ */

/* 1284×2778 pixels at 460ppi  IPHONE iPhone 15 Plus and 15 Pro Max..*/

/* @media only screen 
    and (width: 430px) 
    and (height: 932px) 
    and (-webkit-device-pixel-ratio: 3) { 

    } */




@media screen /* and (max-width: 320px) */and (min-width: 200px) and (max-width: 500px)  {

    .Home {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }
    header {
        display: flex;
        flex-wrap: wrap;
        justify-content:space-between;
        padding: 0 20px;
        height: auto;
        max-width: 100%;
        /* position: fixed; */
        z-index: 10;
        /* width: 60; */
        position: fixed;
        z-index: 10;
        background-color: white;
        overflow: hidden;
       
    }


    

    .Home .parallax-container video {
        margin-top: 60px;
        max-height: 230px;
        display: flex
    }

    /*sector de titulos y 3 imágenes a la derecha y luego izquierda */
    .Home .texto-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0px;

    }

    .Home .texto-item {
        text-align: center;
    }

    .Home .cards .texto-item .titulo-negro {
        font-family: var(--fuente-regular);
        font-size: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .Home .cards .texto-item .subtitulo-dorado {
        font-family: var(--fuente-terciaria);
        font-size: 10px;
        color: var(--colorIcons);
        display: flex;
        justify-content: center;
        text-align: center;
        line-height: 1.5;
    }

    .Home .cards {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .Home .cards .image-cards {
        width: 50%;
        display: flex;
        flex-wrap: nowrap;
        margin: 0 auto;
        padding: 0 20px;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .Home .cards .image-cards img {
        object-fit: cover; /* Otra opción: contain */
        height: 100%;
        width: 100%;
        min-height: 60px;
        max-height: auto;
  
    }

    @media (max-width: 497px) {
        .Home .cards .image-cards img {
            min-height: 100px;
        }
    }
    
    @media (max-width: 395px) {
        .Home .cards .image-cards img {
            min-height: 70px;
        }
    }
    
    @media (max-width: 333px) {
        .Home .cards .image-cards img {
            min-height: 60px;
        }
    }
    @media (max-width: 285px) {
        .Home .cards .image-cards img {
            min-height: 40px;
        }
        }
    

    /*sector de titulo PRODUCTOS-------------------------------- */

    .Home .products article {
        width: 100%;
        margin-left: 0;
        padding: 5px;
    }


    .Home .products-title .letraGrande {
        font-family: var(--fuente-regular);
        font-size: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
     /*    font-weight: bold; */
    }

    .Home .products-title p {
        font-family: var(--fuente-regular);
        font-size: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
    }

/* titulo de sección de contacto----> */

    .Home .contact-products-title p {
      
        display: flex;
        font-size: 20px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 100%;
        font-family: var(--fuente-regular);
        color:var(--colorIcons)
    }

    /* SECTOR DE PRODUCTOS 3 TITULOS 3 IMAGENES DESCRIPCIÓN Y PRECIO: */

    .Home .products {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        min-width: 100px;
        min-height: 100px;
    }

    .Home .products .image-cards {
        /*   width: 40%; */
        margin-left: 5px;
        padding: 0px;
        min-width: 30px;

    }
    .Home .products .image-cards2 {
        /*   width: 40%; */
        margin-left: 5px;
        padding: 0px;
        min-width: 30px;

    }


    .Home .products .image-cards .dueño2 {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); /* Estado inicial */
        transition: box-shadow 0.5s ease; /*transición suave */
        &:hover {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); /* Anula el efecto hover en pantallas pequeñas */
          }
        
    }

    .Home .products img {
        border-radius: 10px;
        margin-top: 0px;
        max-height: 300px;
    }
    .Home .products .vino img {
        border-radius: 10px;
        margin-top: 0px;
        min-height: 340px;
       /*  max-height: 330px; */
    }

    .Home .products .image-cards article p {
        font-size: 20px;
        margin: 0px;
        padding: 0px;
        justify-items: center;
        width: 100%;
    }

    /* descripción */



    .Home .products .image-cards article .descripcion .detail li {
        font-size: 10px;
        padding: 0;
        justify-content: center;
        text-align: justify;
        margin: 0px;
        word-spacing: -1px;
        hyphens: auto;
    }

    .Home .products .detail li .uvitas {
        width: 15px;
        height: 15px;
        margin-top: 10px;
        margin-left: 0px;
        line-height: 0;
        word-spacing: -1px;
    }

    /* precios */

    .Home .products .descripcion .total-precio {
        text-align: right;
        font-family: var(--fuente-terciaria);
        font-size: 12px;
        font-weight: lighter;
        margin-top: 10px;
        margin-right: 10px;
        font-style: italic;
        align-self: flex-end;
    }


    /* frase final de "vinos personalizados con firma..." */

    .Home .fraseFinal {
        text-align: left;
        font-family: var(--fuente-terciaria);
        font-size: 15px;
        color: var(--colorIcons);
        font-weight: bold;
        margin-top: 5px;
        padding: 3px 30px;
        text-align: center;
    }

    /* titulo experiencia propietarios --------------------- */

    .Home .products-title-experience .letraGrandeExperience {
        font-family: var(--fuente-regular);
        font-size: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-weight: bold;
        color: var(--colorIcons);
    }

    .Home .products-title-experience p {
        font-family: var(--fuente-regular);
        font-size: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }

    .Home .products-title-experience .letraGrandeExperience {
        font-family: var(--fuente-regular);
        font-size: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-weight: bold;
        color: var(--colorIcons);
    }

    
.Home .products-title-experience p {
    font-family: var(--fuente-regular);
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 30px 0 30px;
} 

/* prueba ---------------- */

/* Estilos para pantallas pequeñas (menos de 400px) */

    .Home .products .image-cards-experience {
      width: 100%;
      flex-direction: column; 
      align-items: center; 
      padding: 0;
    }
  
    .Home .products .image-cards-experience .experience-container {
      width: 100%;
      min-height: auto; 
      
    }
  
    .container-comilla-linea {
      display: flex;
      align-items: center;
      min-width: auto;
      padding: 0;
        width: 100%;
      
    }
  
    .Home .products .descripcion,
    .Home .products .experience-descripcion {
      font-size: 18px;
      text-align: center;
      margin: 0;
      font-family: var(--fuente-regular);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: var(--colorIcons);
      text-align: center;
      font-weight: lighter;
      margin: 0;
    }
  
    .Home .products .image-cards-experience .experience-container .experience-descripcion .statements { 
      font-size: 16px;
      min-height: auto; 
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: justify;
      padding: 3%;
      word-spacing: -1px;
      hyphens: auto;
      font-family: var(--fuente-terciaria);
      font-weight: lighter;
      margin-top: 1px;
      margin-bottom: 5px;
      color: black;
      line-height: 2;
      min-width: auto;
      line-height: 1.5;
    }
  
  
    .Home .products .experience-descripcion .author-statement {
      clear: both;
      font-family: var(--fuente-terciaria);
      text-align: center;
      font-size: 12px;
      margin-top: 10px;
      font-style: italic;
      font-weight: bold;
    }

  
/* prueba ----------- */

/* prueba de contacto -------------------*/

.Home .products .experience-container {
    display: flex;
    flex-direction: column;
 
    justify-content: center;
    align-items:center ;
    max-width: 100%;
}
 /*
.Home .products .contacto-descripcion,
.Home .products .container-buttons,
.Home .products .author-statement {
    width: 100%; 
    flex-grow: 1; 
}*/

.Home .products .contacto-descripcion {
  font-size: 18px;
  font-family: var(--fuente-regular);
  color: var(--colorIcons);
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-weight: lighter;
  max-width: 100%;

}



.Home .products .experience-container .contacto-descripcion .paises-contacto{
 display:flex;
flex-direction: column;
align-items: center;
justify-content: flex-start; 
}

.Home .products .experience-container .contacto-descripcion  p{
    display: flex;
    font-size: 20px;
    justify-content: center; 
    align-items: center; 
   padding: 10px; 
    width: 100%;
    font-family: var(--fuente-regular)
}

.Home .products .contact-image-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: auto; 
    margin: 3%;
    
}


.container-comilla-linea {
    min-width: auto;
    padding: 0;
}


.Home .products .container-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 10px;
    min-height: 150px;
    max-width: 100%;
}

.Home .products .container-buttons button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
    width: 65%;
    border-radius: 25px;
    font-family: var(--fuente-regular);
    font-size: 10px;
    border: 1px solid var(--colorIcons);
    background-color: white;
    white-space: nowrap;
}

.Home .products .container-buttons .social-media-button {
    display: flex;
    align-items: center;
    width: 65%; 
    background-color: var(--colorButtons);
    padding: 5px;
    background-color: var(--colorIcons);
    height: auto;
    
}

.Home .products .image-cards .dueño2 .contact-media-button {
    display: flex;
    align-items: center;
  /*   width: 65%;  */
    padding: 5px;
    background-color: white;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 25px;
    font-family: var(--fuente-regular);
    font-size: 13px;
    border: 1px solid var(--colorIcons);
    white-space: nowrap;
    margin-top: 10px;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); /* Estado inicial */
    transition: box-shadow 0.5s ease; /*transición suave */
    &:hover {
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35); /* Estado final al hacer hover */
      }
}





.Home .products .image-cards .dueño2 .social-media-button {
    display: flex;
    align-items: center;
  /*   width: 65%;  */
    padding: 5px;
    background-color: var(--colorIcons);
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 25px;
    font-family: var(--fuente-regular);
    font-size: 10px;
    border: 1px solid var(--colorIcons);
    white-space: nowrap;
    margin-top: 10px;

    &:hover {
        animation:tracking-in-expand 1s ease;
        background-color: var(--colorIcons);
      }
}

.our-social-networks-text {
    font-size: 10px;
    font-family: var(--fuente-regular);
    text-align: center;
 /*    font-weight: bold; */
    justify-content: center;
    margin-right: 10px;
}


.Home .products .experience-descripcion .statements p {
    padding: 4%;
    word-spacing: normal; /* Restaurar espaciado normal de palabras */
    hyphens: none; /* Desactivar la separación de sílabas */
    font-family: var(--fuente-terciaria);
    font-size: 8px; 
    margin-top: 5px; 
}

.Home .products .experience-container .author-statement {
   /*  width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

}





@media (max-width: 900px) {
   
    .Home .products {
        max-width: 700px;
        display: flex;
        flex-wrap: nowrap;
        margin: 0 auto;
        padding: 3px 20px;
        align-items: flex-start;
    }

    .Home .products .experience-container {
        display: flex;
        flex-direction: column;
     
        justify-content: center;
        align-items:center ;
        max-width: 100%;
    }
         
    .Home .products .contacto-descripcion {
      font-size: 18px;
      font-family: var(--fuente-regular);
      color: var(--colorIcons);
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      font-weight: lighter;
      max-width: 100%;
    
    }
    
    
    
    .Home .products .experience-container .contacto-descripcion .paises-contacto{
     display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; 
    }
    
    .Home .products .experience-container .contacto-descripcion  p{
        display: flex;
        font-size: 20px;
        justify-content: center; 
        align-items: center; 
       padding: 10px; 
        width: 100%;
        font-family: var(--fuente-regular)
    }
    
    .Home .products .contact-image-cards {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: auto; 
        margin: 3%;
        align-items: flex-start;
        
    }
    
    
    .container-comilla-linea {
        min-width: auto;
        padding: 0;
    }
    
    
    .Home .products .container-buttons {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-top: 10px;
        min-height: 150px;
        max-width: 100%;
    }
    
    .Home .products .container-buttons button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        margin-bottom: 10px;
        width: 65%;
        border-radius: 25px;
        font-family: var(--fuente-regular);
        font-size: 10px;
        border: 1px solid var(--colorIcons);
        background-color: white;
        white-space: nowrap;
    }
    
    .Home .products .container-buttons .social-media-button {
        display: flex;
        align-items: center;
        width: 65%; 
        background-color: var(--colorButtons);
        padding: 5px;
        background-color: var(--colorIcons);
        height: auto;
        
    }
    
    .Home .products .image-cards .dueño2 .contact-media-button {
        display: flex;
        align-items: center;
      /*   width: 65%;  */
        padding: 5px;
        background-color: white;
        height: auto;
    
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        margin-bottom: 10px;
        width: 100%;
        border-radius: 25px;
        font-family: var(--fuente-regular);
        font-size: 13px;
        border: 1px solid var(--colorIcons);
        white-space: nowrap;
        margin-top: 10px;
    
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); /* Estado inicial */
        transition: box-shadow 0.5s ease; /*transición suave */
        &:hover {
            box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35); /* Estado final al hacer hover */
          }
    }
    
    
    
    
    
    .Home .products .image-cards .dueño2 .social-media-button {
        display: flex;
        align-items: center;
      /*   width: 65%;  */
        padding: 5px;
        background-color: var(--colorIcons);
        height: auto;
    
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        margin-bottom: 10px;
        width: 100%;
        border-radius: 25px;
        font-family: var(--fuente-regular);
        font-size: 10px;
        border: 1px solid var(--colorIcons);
        white-space: nowrap;
        margin-top: 10px;
    
        &:hover {
            animation:tracking-in-expand 1s ease;
            background-color: var(--colorIcons);
          }
    }
    
    .our-social-networks-text {
        font-size: 10px;
        font-family: var(--fuente-regular);
        text-align: center;
      /*   font-weight: bold; */
        justify-content: center;
        margin-right: 10px;
    }
    
  

}

/* NEWSLETTER----------------> */
.Home.NewsLetter {
    padding-top: 70px;   
}
.Home .NewsLetter-title h1 {
    text-align: center;  
    width: 100%;
    padding-bottom: 10px;
    margin-top: 0px;
    font-size:30px;
    font-family:var(--fuente-regular);
    color: black;
    font-weight: 200;
}
.Home .NewsLetter-video-buttons {
    text-align: center;  
    width: 100%;
    padding-bottom: 10px;
    margin-top: 0px;
    font-size:30px;
    font-family:var(--fuente-regular);
    color: black;
    font-weight: 200;
}

.NewsLetter-video-buttons button {
    font-size: 20px; /* Reducir el tamaño de la fuente */
    font-family: var(--fuente-regular);
    color: black;
    font-weight: 200;
    padding: 10px 20px; /* Espacio interno dentro del botón */
    margin: 5px; /* Espacio entre los botones */
    border-radius: 10px; /* Borde redondeado */
    background-color: #e0e0e0; /* Color de fondo */
    border: none; /* Quitar el borde */
    cursor: pointer;
  }


.Home.NewsLetter .parallax-container video {
    margin-top:0px;
    margin-bottom: 10px;
     max-height: 700px; 
}
.Home.NewsLetter .parallax-container video {
    margin-top:0px;
    margin-bottom: 10px;
     max-height: 700px; 
}